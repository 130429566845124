export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/index.vue'),
    /* no children */
    meta: {
      "title": "Accueil"
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/contacts.vue'),
    /* no children */
    meta: {
      "title": "Admin Contacts"
    }
  },
  {
    path: '/debug',
    name: 'debug',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/debug.vue'),
    /* no children */
    meta: {
      "title": "Debug"
    }
  },
  {
    path: '/devis',
    name: 'CustomerDevis',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/devis.vue'),
    /* no children */
    meta: {
      "title": "Devis"
    }
  },
  {
    path: '/factures',
    name: 'Invoices',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/factures.vue'),
    /* no children */
    meta: {
      "title": "Factures"
    }
  },
  {
    path: '/interventions',
    name: 'CustomerInterventions',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/interventions.vue'),
    /* no children */
    meta: {
      "title": "Interventions"
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/login.vue'),
    /* no children */
    meta: {
      "title": "Connexion"
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/logout.vue'),
    /* no children */
    meta: {
      "title": "Déconnexion"
    }
  },
  {
    path: '/parametres',
    name: 'Parametres',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/parametres.vue'),
    children: [
      {
        path: 'securite',
        name: 'ParametresSecurite',
        component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/parametres/securite.vue'),
        /* no children */
        meta: {
          "title": "Paramètres de Sécurité"
        }
      }
    ],
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/password.vue'),
    /* no children */
    meta: {
      "title": "Récupèration mot de passe"
    }
  },
  {
    path: '/password-create',
    name: 'PasswordCreate',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/password-create.vue'),
    /* no children */
    meta: {
      "title": "Création du mot de passe"
    }
  },
  {
    path: '/recherche',
    name: 'Recherche',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/recherche.vue'),
    /* no children */
    meta: {
      "title": "Recherche"
    }
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/services.vue'),
    /* no children */
    meta: {
      "title": "Services"
    }
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: () => import('/builds/ovea-dev/si-espace-client/ui/ui-public/src/pages/tickets.vue'),
    /* no children */
    meta: {
      "title": "Tickets"
    }
  }
]

export function handleHotUpdate(_router, _hotUpdateCallback) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
    import.meta.hot.data.router_hotUpdateCallback = _hotUpdateCallback
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    // call the hotUpdateCallback for custom updates
    import.meta.hot.data.router_hotUpdateCallback?.(mod.routes)
    const route = router.currentRoute.value
    router.replace({
      ...route,
      // NOTE: we should be able to just do ...route but the router
      // currently skips resolving and can give errors with renamed routes
      // so we explicitly set remove matched and name
      name: undefined,
      matched: undefined,
      force: true
    })
  })
}

