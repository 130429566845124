import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client/core';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { withScalars } from 'apollo-link-scalars';
import { Kind, OperationTypeNode } from 'graphql';
import { createClient } from 'graphql-ws';
import type { App } from 'vue';

import { localAuth } from '@/plugins/auth';
import authService from '@/plugins/auth.service';

import baseDebug from './debug';
import schema from './schema';

const debug = baseDebug.extend('apollo');

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${import.meta.env.VITE_API_BACKEND}${import.meta.env.VITE_API_GRAPHQL}`,
});

const linkChain = createPersistedQueryLink({
  sha256: async (query: string): Promise<string> => {
    const sha = new Sha256();
    sha.update(query);
    const digest = await sha.digest();

    return digest.reduce(
      (previous, int) =>
        /*
         * Ce petit bout là prend un entier 8 bits, le transforme en chaîne de
         * caractères, et s'assure que le résultat fait bien 2 caractères en
         * ajoutant un 0 au début si besoin.
         */
        previous + int.toString(16).padStart(2, '0'),
      ''
    );
  },
});

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    // Ajouter ici les types dont l'ID ne s'appelle pas `id`
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const bearer = authService.token;

  if (bearer === undefined) {
    debug('authMiddleware: no token');
  } else {
    let authorization = `Bearer ${bearer}`;

    debug('authMiddleware: token: %o', bearer);

    if (localAuth.cur_customer_id === undefined) {
      debug('authMiddleware: no CurCustId');
    } else {
      debug('authMiddleware: CurCustId: %o', localAuth.cur_customer_id);
      authorization += ` CurCustId:${localAuth.cur_customer_id}`;
    }

    operation.setContext({
      headers: {
        authorization,
      },
    });
  }

  return forward(operation);
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${import.meta.env.VITE_API_WSBACKEND}${import.meta.env.VITE_API_SUBSCRIPTIONS}`.replace(/^http/, 'ws'),

    lazy: false,

    onNonLazyError(error) {
      debug('onNonLazyError: %o', error);
    },

    connectionParams() {
      const bearer = authService.token;

      if (bearer === undefined) {
        debug('connectionParameters: no token');
      } else {
        let authorization = `Bearer ${bearer}`;

        debug('connectionParameters: token: %o', bearer);

        if (localAuth.cur_customer_id === undefined) {
          debug('connectionParameters: no CurCustId');
        } else {
          authorization += ` CurCustId:${localAuth.cur_customer_id}`;
          debug('connectionParameters: CurCustId: %o', localAuth.cur_customer_id);
        }

        return {
          Authorization: authorization,
        };
      }

      return {};
    },
  })
);

/*
 * The split function takes three parameters:
 *
 * * A function that's called for each operation to execute
 * * The Link to use for an operation if the function returns a "truthy" value
 * * The Link to use for an operation if the function returns a "falsy" value
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    debug('splitLink: definition: %o', definition);

    return definition.kind === Kind.OPERATION_DEFINITION && definition.operation === OperationTypeNode.SUBSCRIPTION;
  },
  wsLink,
  // eslint-disable-next-line unicorn/prefer-spread
  authMiddleware.concat(withScalars({ schema })).concat(linkChain).concat(httpLink)
);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: splitLink,
  connectToDevTools: true,
  cache,

  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default {
  async install(app: App): Promise<void> {
    debug('install');
    app.provide(DefaultApolloClient, apolloClient);
  },
};
