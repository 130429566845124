<template>
  <menu>
    <aside class="menu">
      <ul class="menu-list">
        <li>
          <RouterLink class="navbar-item" :to="{ name: 'home' }">Accueil</RouterLink>
        </li>

        <li>
          <a href="/doc.pdf" class="navbar-item">Documentation</a>
        </li>
      </ul>

      <template v-if="$can('ADMIN')">
        <p class="menu-label">Administration</p>

        <ul class="menu-list">
          <li>
            <RouterLink  :to="{ name: 'Contacts' }">Contacts</RouterLink>
          </li>
        </ul>
      </template>

      <template v-if="$can('FACTURATION')">
        <p class="menu-label">Comptabilité</p>

        <ul class="menu-list">
          <li>
            <RouterLink  :to="{ name: 'Invoices' }">Factures</RouterLink>
          </li>
          <!--
            <li>
            <a class="strike">Contrats</a>
            </li>
          -->
        </ul>
      </template>

      <!--
        TODO: Remettre tout ça, ça fonctionne

        <p class="menu-label">Commercial</p>

        <ul class="menu-list">
        <li>
        <RouterLink data-test="devis" :to="{ name: 'CustomerDevis' }">Devis</RouterLink>
        </li>

        <li>
        <RouterLink data-test="services" :to="{ name: 'Services' }">Services</RouterLink>
        </li>
        </ul>

        <p class="menu-label">Technique</p>

        <ul class="menu-list">
        <li>
        <RouterLink data-test="interventions" :to="{ name: 'CustomerInterventions' }">Interventions</RouterLink>
        </li>

        <li>
        <RouterLink data-test="tickets" :to="{ name: 'Tickets' }">Tickets</RouterLink>
        </li>
        </ul>
      -->
      <p class="menu-label">Paramètres</p>

      <ul class="menu-list">
        <li>
          <RouterLink  :to="{ name: 'ParametresSecurite' }">Gestion des accès</RouterLink>
        </li>
      </ul>
    </aside>
  </menu>
</template>

<script setup lang="ts">
import baseDebug from './debug';

defineOptions({ name: 'NavMenu' });

baseDebug.extend('NavMenu')('init');
</script>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/mixins.sass';

.active {
  background-color: inherit !important;
  backdrop-filter: brightness(130%) saturate(70%) !important;
}

menu {
  margin-left: 1rem;
  margin-top: 0px;
  padding: 0;

  .menu-list a {
    // backdrop-filter: brightness(110%) saturate(90%);

    &:hover {
      background-color: inherit !important;
      backdrop-filter: brightness(150%) saturate(50%);
    }
  }

  @include tablet {
    & > .menu > ul.menu-list:first-of-type > li:first-of-type > a {
      padding-left: 0px;
    }
  }

  @include mobile {
    z-index: 10;
    margin-left: 0px !important;
    padding-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    overflow-x: hidden;

    &:hover {
      background-color: inherit !important;
      backdrop-filter: brightness(150%);
      display: block;
      margin-left: 1rem !important;
      width: 200px;
    }

    &:not(:hover) {
      white-space: nowrap;

      ul,
      .menu-label {
        padding-left: 0;
        padding-right: 0;
      }

      .menu-label,
      .menu-list a {
        position: relative;
        text-indent: -9999px;
        width: 100%;

        &:hover {
          background-color: inherit !important;
        }
      }

      .menu-list a:after {
        align-items: center;
        bottom: 0;
        content: '•';
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        text-align: center;
        text-indent: 0;
        top: 0;
        width: 2rem;
      }
    }
  }
}
</style>
