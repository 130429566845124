import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type { PayloadTypes, PublicPayload, PublicPayloadInput } from '@ovea-dev/si-client-common-message-types';
import type { Duration } from 'luxon';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: string; output: string };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: Date | string; output: Date };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date | string; output: Date };
  /**
   *
   *     A string representing a duration conforming to the ISO8601 standard,
   *     such as: P1W1DT13H23M34S
   *     P is the duration designator (for period) placed at the start of the duration representation.
   *     Y is the year designator that follows the value for the number of years.
   *     M is the month designator that follows the value for the number of months.
   *     W is the week designator that follows the value for the number of weeks.
   *     D is the day designator that follows the value for the number of days.
   *     T is the time designator that precedes the time components of the representation.
   *     H is the hour designator that follows the value for the number of hours.
   *     M is the minute designator that follows the value for the number of minutes.
   *     S is the second designator that follows the value for the number of seconds.
   *
   *     Note the time designator, T, that precedes the time value.
   *
   *     Matches moment.js, Luxon and DateFns implementations
   *     ,/. is valid for decimal places and +/- is a valid prefix
   *
   */
  Duration: { input: Duration<true>; output: Duration<true> };
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: object; output: object };
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: string; output: string };
  /** Le payload d'un message */
  MessagePayload: { input: PublicPayloadInput; output: PublicPayload };
  /** Type d'une payload */
  MessagePayloadType: { input: PayloadTypes; output: PayloadTypes };
  /** A string that cannot be passed as an empty value */
  NonEmptyString: { input: string; output: string };
  /** Floats that will have a value of 0 or more. */
  NonNegativeFloat: { input: number; output: number };
  /** Integers that will have a value of 0 or more. */
  NonNegativeInt: { input: number; output: number };
  /** Integers that will have a value greater than 0. */
  PositiveInt: { input: number; output: number };
  /** A field whose value is a generic Universally Unique Identifier: https://en.wikipedia.org/wiki/Universally_unique_identifier. */
  UUID: { input: string; output: string };
}

/** Input fields for auth generate authentication */
export interface AuthAuthenticationGenerateInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The user to get the challenge for U2F auth */
  email: Scalars['NonEmptyString']['input'];
  /** The id for the token */
  id: Scalars['NonEmptyString']['input'];
}

/** Output of auth generate authentication */
export type AuthAuthenticationGeneratePayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** The object containing the authentication information */
  options: Scalars['JSONObject']['output'];
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Input fields for auth */
export interface AuthInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The email to log in with */
  email: Scalars['NonEmptyString']['input'];
  /** A One Time Password */
  otp?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The password for the email */
  password: Scalars['NonEmptyString']['input'];
  /** Wether the token will last longer */
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  /** An object containing the U2F response, if required */
  response?: InputMaybe<Scalars['JSONObject']['input']>;
}

/** Output of auth */
export type AuthPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Our JWT auth token */
  token: Scalars['JWT']['output'];
};

/** Input fields for auth generate registration */
export interface AuthRegistrationGenerateInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The id to generate for */
  id: Scalars['NonEmptyString']['input'];
  /** The name to generate for */
  name: Scalars['NonEmptyString']['input'];
}

/** Output of auth generate registration */
export type AuthRegistrationGeneratePayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** The options to be used to generate a registration token */
  options: Scalars['JSONObject']['output'];
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Input fields for auth generate registration */
export interface AuthRegistrationVerifyInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The registration response to verify */
  response: Scalars['JSONObject']['input'];
}

/** Output of auth generate registration */
export type AuthRegistrationVerifyPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Wether the registration verification was ok */
  ok: Scalars['Boolean']['output'];
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/**
 * Enumeration of possible roles.
 *
 * `UNKNOWN` does not exist.
 */
export enum AuthRole {
  Admin = 'ADMIN',
  Alerte = 'ALERTE',
  Any = 'ANY',
  Dpo = 'DPO',
  Facturation = 'FACTURATION',
  Legal = 'LEGAL',
  Technique = 'TECHNIQUE',
}

/** Base of all Connections */
export interface Connection {
  /** A list of edges which contains the nodes and cursor to aid in pagination. */
  edges: Edge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
}

/** Base of all Connections */
export interface ConnectionUuid {
  /** A list of edges which contains the nodes and cursor to aid in pagination. */
  edges: EdgeUuid[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
}

/** Input fields for createPassword */
export interface CreatePasswordInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The code */
  code: Scalars['NonEmptyString']['input'];
  /** The new password */
  password: Scalars['NonEmptyString']['input'];
  /** The token sent via email */
  token: Scalars['NonEmptyString']['input'];
}

/**
 * Payload CreatePassword
 *
 * Il n'y a pas de réponse, la mutation répond de la même façon qu'elle ait
 * fonctionné ou pas, et ce, pour éviter de donner un indice sur l'existence ou non
 * de l'adresse email dans la base.
 */
export type CreatePasswordPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** updateUser Input */
export interface CreateVtigerContactInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The contact data */
  contact: VtigerContactInput;
  /** The customer we want to link it to */
  customerId?: InputMaybe<Scalars['UUID']['input']>;
}

/** updateUser payload */
export type CreateVtigerContactPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Un Customer */
export type Customer = NodeUuid & {
  /** The accountContacts for this customer */
  accountContacts: VTigerAccountContact[];
  id: Scalars['UUID']['output'];
  name: Scalars['NonEmptyString']['output'];
};

/** Un Customer */
export interface CustomerAccountContactsArgs {
  orderBy?: InputMaybe<VTigerAccountContactOrderBy[]>;
}

/** A `Customer` edge in the connection. */
export type CustomerEdge = EdgeUuid & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The current node */
  node: Customer;
};

/** A connection to a list of `Customer` values. */
export type CustomersConnection = ConnectionUuid & {
  /** A list of edges which contains the nodes and cursor to aid in pagination. */
  edges: CustomerEdge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Base of all Edges */
export interface Edge {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The current node */
  node?: Maybe<Node>;
}

/** Base of all Edges */
export interface EdgeUuid {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The current node */
  node?: Maybe<NodeUuid>;
}

/** EvenementIntervention */
export type EvenementIntervention = NodeUuid & {
  action: Scalars['NonEmptyString']['output'];
  /** Est-ce que cette intervention a été effectuée dans en astreinte ? */
  astreinte: Scalars['Boolean']['output'];
  debut: Scalars['String']['output'];
  description: Scalars['NonEmptyString']['output'];
  /** The Netbox Devices of that Astreinte */
  devices: NetboxDevice[];
  duree: Scalars['Duration']['output'];
  fin?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** Les services de cette Evenement */
  services: EvenementService[];
  /** Get the tickets linked to that astreinte */
  tickets: OtrsTicket[];
  /** Get the vms affected by that astreinte */
  vms: OVirtVm[];
};

/** EvenementIntervention */
export interface EvenementInterventionDebutArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** EvenementIntervention */
export interface EvenementInterventionDevicesArgs {
  orderBy?: InputMaybe<NetboxDevicesOrderBy[]>;
}

/** EvenementIntervention */
export interface EvenementInterventionFinArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** EvenementIntervention */
export interface EvenementInterventionServicesArgs {
  orderBy?: InputMaybe<EvenementServicesOrderBy[]>;
}

/** EvenementIntervention */
export interface EvenementInterventionTicketsArgs {
  orderBy?: InputMaybe<OtrsTicketsOrderBy[]>;
}

/** EvenementIntervention */
export interface EvenementInterventionVmsArgs {
  orderBy?: InputMaybe<OVirtVmsOrderBy[]>;
}

/** A `EvenementIntervention` edge in the connection. */
export type EvenementInterventionEdge = EdgeUuid & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `EvenementIntervention` at the end of the edge. */
  node: EvenementIntervention;
};

/** A connection to a list of `EvenementIntervention` values. */
export type EvenementInterventionsConnection = ConnectionUuid & {
  /** A list of edges which contains the `EvenementIntervention` and cursor to aid in pagination. */
  edges: EvenementInterventionEdge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EvenementIntervention` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Methods to use when ordering `EvenementIntervention`. */
export enum EvenementInterventionsOrderBy {
  ActionAsc = 'ACTION_ASC',
  ActionDesc = 'ACTION_DESC',
  CommentaireAsc = 'COMMENTAIRE_ASC',
  CommentaireDesc = 'COMMENTAIRE_DESC',
  DebutAsc = 'DEBUT_ASC',
  DebutDesc = 'DEBUT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DureeAsc = 'DUREE_ASC',
  DureeDesc = 'DUREE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
}

/** Search astreintes */
export interface EvenementInterventionsSearch {
  /** Search by action */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Search by commentaire */
  commentaire?: InputMaybe<Scalars['String']['input']>;
  /** Search by description */
  description?: InputMaybe<Scalars['String']['input']>;
}

/** Un service d'astreinte */
export type EvenementService = NodeUuid & {
  id: Scalars['UUID']['output'];
  service: Scalars['NonEmptyString']['output'];
};

/** Tri services */
export enum EvenementServicesOrderBy {
  ServiceAsc = 'SERVICE_ASC',
  ServiceDesc = 'SERVICE_DESC',
}

/** Input pour getInvoice */
export interface GetInvoiceInput {
  /** id */
  id: Scalars['UUID']['input'];
}

/** Payload renvoyé par le mutation getInvoice */
export interface GetInvoicePayload {
  /** Contenu du fichier sous forme de base64 */
  content: Scalars['String']['output'];
  /** La query */
  query?: Maybe<Query>;
}

/** Icepepper Invoice */
export type IcepepperInvoice = NodeUuid & {
  /** Date d'émission */
  date: Scalars['String']['output'];
  /** Date d'échéance */
  dueDate: Scalars['String']['output'];
  /** Identifiant */
  id: Scalars['UUID']['output'];
  /** Numéro de facture */
  number: Scalars['String']['output'];
  /** Total HT */
  total: Scalars['String']['output'];
  /** Total TTC */
  totalAmount: Scalars['String']['output'];
  /** Total TVA */
  totalVat: Scalars['String']['output'];
};

/** Icepepper Invoice */
export interface IcepepperInvoiceDateArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Icepepper Invoice */
export interface IcepepperInvoiceDueDateArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Trier les invoices icepepper */
export enum IcepepperInvoiceOrderBy {
  /** Tri par date */
  DateAsc = 'DATE_ASC',
  /** Tri par date descendant */
  DateDesc = 'DATE_DESC',
  /** Tri par numéro de facture */
  NumberAsc = 'NUMBER_ASC',
  /** Tri par numéro de facture descendant */
  NumberDesc = 'NUMBER_DESC',
  /** Tri par total de facture */
  TotalAmountAsc = 'TOTAL_AMOUNT_ASC',
  /** Tri par total de facture descendant */
  TotalAmountDesc = 'TOTAL_AMOUNT_DESC',
}

/** Icepepper Product */
export type IcepepperProduct = NodeUuid & {
  /** Get an array of all the categories of the product */
  allCategories: IcepepperProductCategory[];
  description?: Maybe<Scalars['String']['output']>;
  engagementPeriod?: Maybe<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['NonNegativeFloat']['output'];
  recurrent: Scalars['Boolean']['output'];
  sku: Scalars['String']['output'];
  unitIdentifier: Scalars['String']['output'];
  unitTitle: Scalars['String']['output'];
  vatRate: Scalars['NonNegativeFloat']['output'];
};

/** Icepepper ProductCategory */
export type IcepepperProductCategory = NodeUuid & {
  /** The children categories */
  children: IcepepperProductCategory[];
  id: Scalars['UUID']['output'];
  /** The parent category */
  parent?: Maybe<IcepepperProductCategory>;
  title: Scalars['String']['output'];
};

/** Icepepper SaleOrder */
export type IcepepperSaleOrder = NodeUuid & {
  amount: Scalars['Float']['output'];
  assignTo?: Maybe<Scalars['String']['output']>;
  customerReference?: Maybe<Scalars['String']['output']>;
  date: Scalars['String']['output'];
  entity: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  number: Scalars['String']['output'];
};

/** Icepepper SaleOrder */
export interface IcepepperSaleOrderDateArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Icepepper Service */
export type IcepepperService = NodeUuid & {
  dateEnd?: Maybe<Scalars['String']['output']>;
  dateStart: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  initialTerm?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  periodicityDuration?: Maybe<Scalars['String']['output']>;
  periodicityIdentifier?: Maybe<Scalars['String']['output']>;
  periodicityTitle?: Maybe<Scalars['String']['output']>;
  /** The Product type of this service */
  product: IcepepperProduct;
  reference?: Maybe<Scalars['String']['output']>;
  /** le SO qui crée ce service */
  saleOrder?: Maybe<IcepepperSaleOrder>;
  statusIdentifier: Scalars['String']['output'];
  statusTitle: Scalars['String']['output'];
  tacitAgreement: Scalars['Boolean']['output'];
};

/** Icepepper Service */
export interface IcepepperServiceDateEndArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Icepepper Service */
export interface IcepepperServiceDateStartArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Icepepper Service */
export interface IcepepperServiceInitialTermArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Edge Icepepper Service */
export type IcepepperServiceEdge = EdgeUuid & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The current node */
  node: IcepepperService;
};

/** A connection to a list of `IcepepperService` values. */
export type IcepepperServicesConnection = ConnectionUuid & {
  /** A list of edges which contains the nodes and cursor to aid in pagination. */
  edges: IcepepperServiceEdge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Les tris possible pour un IcepepperService */
export enum IcepepperServicesOrderBy {
  DateEndAsc = 'DATE_END_ASC',
  DateEndDesc = 'DATE_END_DESC',
  DateStartAsc = 'DATE_START_ASC',
  DateStartDesc = 'DATE_START_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InitialTermAsc = 'INITIAL_TERM_ASC',
  InitialTermDesc = 'INITIAL_TERM_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ReferenceAsc = 'REFERENCE_ASC',
  ReferenceDesc = 'REFERENCE_DESC',
  StatusTitleAsc = 'STATUS_TITLE_ASC',
  StatusTitleDesc = 'STATUS_TITLE_DESC',
}

/** Payload du logout */
export interface LogoutPayload {
  /** Loged out ok */
  ok: Scalars['Boolean']['output'];
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
}

/** Message depuis l'API */
export interface Message {
  payload: Scalars['MessagePayload']['output'];
  user?: Maybe<User>;
}

export interface Mutation {
  /** Get a JWT Token */
  auth: AuthPayload;
  /** Generate the authentication parameters for WebAuthN */
  authAuthenticationGenerate: AuthAuthenticationGeneratePayload;
  /** Generate the registration parameters for WebAuthN */
  authRegistrationGenerate: AuthRegistrationGeneratePayload;
  /** Verify the registration parameters for WebAuthN */
  authRegistrationVerify: AuthRegistrationVerifyPayload;
  /** Création d'un mot de passe via token et code SMS */
  createPassword: CreatePasswordPayload;
  /** Ajouter un contact dans vtiger */
  createVtigerContact: CreateVtigerContactPayload;
  /** Récupérer une facture */
  getInvoice: GetInvoicePayload;
  /** Invalider le token */
  logout: LogoutPayload;
  /** Récupération d'un mot de passe via token */
  recoverPassword: RecoverPasswordPayload;
  /** Envoi d'un token pour récupération du mot de passe */
  recoverSend: RecoverSendPayload;
  /** Envoi d'un code en fonction pour vérifier le mobile */
  sendCode: SendCodePayload;
  /** Send a message */
  sendMessage: SendMessagePayload;
  /** changes the password */
  updatePassword: UpdatePasswordPayload;
  /** Mets à jour un contact dans vtiger */
  updateVtigerContact: UpdateVtigerContactPayload;
  /** Mise à jour des rôles d'un contact */
  updateVtigerContactRoles: UpdateVtigerContactRolesPayload;
}

export interface MutationAuthArgs {
  input: AuthInput;
}

export interface MutationAuthAuthenticationGenerateArgs {
  input: AuthAuthenticationGenerateInput;
}

export interface MutationAuthRegistrationGenerateArgs {
  input: AuthRegistrationGenerateInput;
}

export interface MutationAuthRegistrationVerifyArgs {
  input: AuthRegistrationVerifyInput;
}

export interface MutationCreatePasswordArgs {
  input: CreatePasswordInput;
}

export interface MutationCreateVtigerContactArgs {
  input: CreateVtigerContactInput;
}

export interface MutationGetInvoiceArgs {
  input: GetInvoiceInput;
}

export interface MutationRecoverPasswordArgs {
  input: RecoverPasswordInput;
}

export interface MutationRecoverSendArgs {
  input: RecoverSendInput;
}

export interface MutationSendCodeArgs {
  input: SendCodeInput;
}

export interface MutationSendMessageArgs {
  input: SendMessageInput;
}

export interface MutationUpdatePasswordArgs {
  input: UpdatePasswordInput;
}

export interface MutationUpdateVtigerContactArgs {
  input: UpdateVtigerContactInput;
}

export interface MutationUpdateVtigerContactRolesArgs {
  input: UpdateVtigerContactRolesInput;
}

/** The generic Mutation Payload content */
export interface MutationPayload {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
}

/** Device Netbox */
export type NetboxDevice = Node & {
  assetTag?: Maybe<Scalars['String']['output']>;
  customFields?: Maybe<Scalars['JSONObject']['output']>;
  display: Scalars['String']['output'];
  face?: Maybe<Scalars['String']['output']>;
  id: Scalars['PositiveInt']['output'];
  name?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  serial?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

/** A `NetboxDevice` edge in the connection. */
export type NetboxDeviceEdge = Edge & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The current node */
  node: NetboxDevice;
};

/** A connection to a list of `NetboxDevice` values. */
export type NetboxDevicesConnection = Connection & {
  edges: NetboxDeviceEdge[];
  pageInfo: PageInfo;
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Methods to use when ordering `Device`. */
export enum NetboxDevicesOrderBy {
  DisplayAsc = 'DISPLAY_ASC',
  DisplayDesc = 'DISPLAY_DESC',
  FaceAsc = 'FACE_ASC',
  FaceDesc = 'FACE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
}

/** Base of all Nodes */
export interface Node {
  id: Scalars['PositiveInt']['output'];
}

/** Base of all Nodes */
export interface NodeUuid {
  id: Scalars['UUID']['output'];
}

/** VM oVirt */
export type OVirtVm = NodeUuid & {
  comment: Scalars['String']['output'];
  creationTime: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  fqdn?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  json: Scalars['JSONObject']['output'];
  name: Scalars['String']['output'];
  startTime?: Maybe<Scalars['String']['output']>;
  stopTime?: Maybe<Scalars['String']['output']>;
};

/** VM oVirt */
export interface OVirtVmCreationTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** VM oVirt */
export interface OVirtVmStartTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** VM oVirt */
export interface OVirtVmStopTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** A `OVirtVm` edge in the connection. */
export type OVirtVmEdge = EdgeUuid & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The current node */
  node: OVirtVm;
};

/** A connection to a list of `OVirtVm` values. */
export type OVirtVmsConnection = ConnectionUuid & {
  /** A list of edges which contains the nodes and cursor to aid in pagination. */
  edges: OVirtVmEdge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Methods to use when ordering `OVirtVm`. */
export enum OVirtVmsOrderBy {
  CreationTimeAsc = 'CREATION_TIME_ASC',
  CreationTimeDesc = 'CREATION_TIME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FqdnAsc = 'FQDN_ASC',
  FqdnDesc = 'FQDN_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  StopTimeAsc = 'STOP_TIME_ASC',
  StopTimeDesc = 'STOP_TIME_DESC',
}

/** Tickets OTRS */
export type OtrsTicket = Node & {
  archiveFlag: Scalars['String']['output'];
  changeBy: Scalars['Int']['output'];
  changed: Scalars['String']['output'];
  createBy: Scalars['Int']['output'];
  created: Scalars['String']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  /** The user this ticket originated from */
  customerUser?: Maybe<User>;
  customerUserId?: Maybe<Scalars['String']['output']>;
  escalationDestinationIn?: Maybe<Scalars['String']['output']>;
  escalationDestinationTime?: Maybe<Scalars['String']['output']>;
  escalationResponseTime?: Maybe<Scalars['String']['output']>;
  escalationSolutionTime?: Maybe<Scalars['String']['output']>;
  escalationTime?: Maybe<Scalars['Int']['output']>;
  escalationTimeWorkingTime?: Maybe<Scalars['Int']['output']>;
  escalationUpdateTime?: Maybe<Scalars['String']['output']>;
  firstLock?: Maybe<Scalars['String']['output']>;
  firstResponse?: Maybe<Scalars['String']['output']>;
  firstResponseDiffInMin?: Maybe<Scalars['Int']['output']>;
  firstResponseInMin?: Maybe<Scalars['Int']['output']>;
  firstResponseTime?: Maybe<Scalars['Int']['output']>;
  firstResponseTimeDestinationTime?: Maybe<Scalars['String']['output']>;
  firstResponseTimeEscalation?: Maybe<Scalars['Boolean']['output']>;
  firstResponseTimeNotification?: Maybe<Scalars['Boolean']['output']>;
  firstResponseTimeWorkingTime?: Maybe<Scalars['Int']['output']>;
  id: Scalars['PositiveInt']['output'];
  lock: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  priority: Scalars['String']['output'];
  queue: Scalars['String']['output'];
  responsible: Scalars['String']['output'];
  service?: Maybe<Scalars['String']['output']>;
  sla?: Maybe<Scalars['String']['output']>;
  solutionDiffInMin?: Maybe<Scalars['Int']['output']>;
  solutionInMin?: Maybe<Scalars['Int']['output']>;
  solutionTime?: Maybe<Scalars['Int']['output']>;
  solutionTimeDestinationTime?: Maybe<Scalars['String']['output']>;
  solutionTimeEscalation?: Maybe<Scalars['Boolean']['output']>;
  solutionTimeNotification?: Maybe<Scalars['Boolean']['output']>;
  solutionTimeWorkingTime?: Maybe<Scalars['Int']['output']>;
  state: Scalars['String']['output'];
  stateType: Scalars['String']['output'];
  ticketNumber: Scalars['String']['output'];
  timeUnit: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updateTime?: Maybe<Scalars['String']['output']>;
  updateTimeDestinationTime?: Maybe<Scalars['String']['output']>;
  updateTimeEscalation?: Maybe<Scalars['Boolean']['output']>;
  updateTimeNotification?: Maybe<Scalars['Boolean']['output']>;
  updateTimeWorkingTime?: Maybe<Scalars['Int']['output']>;
};

/** Tickets OTRS */
export interface OtrsTicketChangedArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketCreatedArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketEscalationDestinationTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketEscalationResponseTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketEscalationSolutionTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketEscalationUpdateTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketFirstLockArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketFirstResponseArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketFirstResponseTimeDestinationTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketSolutionTimeDestinationTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketUpdateTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** Tickets OTRS */
export interface OtrsTicketUpdateTimeDestinationTimeArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** A `OtrsTicket` edge in the connection. */
export type OtrsTicketEdge = Edge & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `OtrsTicket` at the end of the edge. */
  node: OtrsTicket;
};

/** A connection to a list of `OtrsTicket` values. */
export type OtrsTicketsConnection = Connection & {
  /** A list of edges which contains the `OtrsTicket` and cursor to aid in pagination. */
  edges: OtrsTicketEdge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OtrsTicket` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Les tris possible pour un OtrsTicket */
export enum OtrsTicketsOrderBy {
  ChangedAsc = 'CHANGED_ASC',
  ChangedDesc = 'CHANGED_DESC',
  CreatedAsc = 'CREATED_ASC',
  CreatedDesc = 'CREATED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  QueueAsc = 'QUEUE_ASC',
  QueueDesc = 'QUEUE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  TicketNumberAsc = 'TICKET_NUMBER_ASC',
  TicketNumberDesc = 'TICKET_NUMBER_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
}

/** Information about pagination in a connection. */
export interface PageInfo {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
}

export interface Query {
  /** Check if auth is still valid */
  authed: Scalars['Boolean']['output'];
  /** Get one customer */
  customer?: Maybe<Customer>;
  /** Get all EvenementInterventions */
  evenementInterventions: EvenementInterventionsConnection;
  /** Return the list of phones for a recover token */
  hasMobile: Array<Scalars['NonEmptyString']['output']>;
  /** Les factures du client */
  invoices: IcepepperInvoice[];
  /** List all quotes */
  quotes: VTigerQuotesConnection;
  /** List all Icepepper Services */
  services: IcepepperServicesConnection;
  /** Get all tickets */
  tickets: OtrsTicketsConnection;
  /** Get one user */
  user?: Maybe<User>;
  /** List all users */
  users: UsersConnection;
}

export interface QueryEvenementInterventionsArgs {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<EvenementInterventionsOrderBy[]>;
  search?: InputMaybe<EvenementInterventionsSearch>;
}

export interface QueryHasMobileArgs {
  token: Scalars['NonEmptyString']['input'];
}

export interface QueryInvoicesArgs {
  orderBy?: InputMaybe<IcepepperInvoiceOrderBy[]>;
}

export interface QueryQuotesArgs {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<VTigerQuotesOrderBy[]>;
  searchTs?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryServicesArgs {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<IcepepperServicesOrderBy[]>;
  searchTs?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryTicketsArgs {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<OtrsTicketsOrderBy[]>;
  search?: InputMaybe<TicketsSearch>;
  searchTs?: InputMaybe<Scalars['String']['input']>;
}

export interface QueryUserArgs {
  id: Scalars['UUID']['input'];
}

export interface QueryUsersArgs {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<UsersOrderBy[]>;
  search?: InputMaybe<UsersSearch>;
  searchTs?: InputMaybe<Scalars['String']['input']>;
}

/** Input fields for recoverPassword */
export interface RecoverPasswordInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The new password */
  password: Scalars['NonEmptyString']['input'];
  /** The token sent via email */
  token: Scalars['NonEmptyString']['input'];
}

/**
 * Payload RecoverPassword
 *
 * Il n'y a pas de réponse, la mutation répond de la même façon qu'elle ait
 * fonctionné ou pas, et ce, pour éviter de donner un indice sur l'existence ou non
 * de l'adresse email dans la base.
 */
export type RecoverPasswordPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Input fields for recoverSend */
export interface RecoverSendInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The email we want a token for */
  email: Scalars['NonEmptyString']['input'];
}

/**
 * Payload RecoverSend
 *
 * Il n'y a pas de réponse, la mutation répond de la même façon qu'elle ait
 * fonctionné ou pas, et ce, pour éviter de donner un indice sur l'existence ou non
 * de l'adresse email dans la base.
 */
export type RecoverSendPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Input fields for recoverSend */
export interface SendCodeInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The phone number we want to send the code to */
  mobile: Scalars['NonEmptyString']['input'];
  /** The token used for the auth */
  token: Scalars['NonEmptyString']['input'];
}

/**
 * Payload SendCode
 *
 * Il n'y a pas de réponse, la mutation répond de la même façon qu'elle ait
 * fonctionné ou pas, et ce, pour éviter de donner un indice sur l'existence ou non
 * de l'adresse email dans la base.
 */
export type SendCodePayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** SendMessage input */
export interface SendMessageInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The message payload to send */
  payload: Scalars['MessagePayload']['input'];
}

/** SendMessage Payload */
export interface SendMessagePayload {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** The message we just sent */
  message: Message;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
}

export interface Subscription {
  /** Subscribe to those Messages */
  getMessage: Message;
}

export interface SubscriptionGetMessageArgs {
  type: Array<Scalars['MessagePayloadType']['input']>;
}

/** Recherche de tickets */
export interface TicketsSearch {
  /** Search by ticket number */
  ticketNumber: Scalars['String']['input'];
}

/** updatePassword Input */
export interface UpdatePasswordInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The old password */
  oldPassword: Scalars['NonEmptyString']['input'];
  /** The new password */
  password: Scalars['NonEmptyString']['input'];
}

/** updatePassword payload */
export type UpdatePasswordPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** updateVtigerContact Input */
export interface UpdateVtigerContactInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The new contact data */
  contact: VtigerContactInput;
  /** The contactNo for the contact we want to update */
  contactNo: Scalars['NonEmptyString']['input'];
}

/** updateVtigerContact payload */
export type UpdateVtigerContactPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** updateUser Input */
export interface UpdateVtigerContactRolesInput {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['NonEmptyString']['input']>;
  /** The contactNo for the contact we want to update */
  contactNo: Scalars['NonEmptyString']['input'];
  /** List of new roles for that contact */
  roles: AuthRole[];
}

/** updateUser payload */
export type UpdateVtigerContactRolesPayload = MutationPayload & {
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['NonEmptyString']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Le type User */
export type User = NodeUuid & {
  /** The accountContacts for this user */
  accountContacts: VTigerAccountContact[];
  email: Scalars['NonEmptyString']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['NonEmptyString']['output'];
};

/** Le type User */
export interface UserAccountContactsArgs {
  orderBy?: InputMaybe<VTigerAccountContactOrderBy[]>;
}

/** A `User` edge in the connection. */
export type UserEdge = EdgeUuid & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `User` at the end of the edge. */
  node: User;
};

/** A connection to a list of `User` values. */
export type UsersConnection = ConnectionUuid & {
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: UserEdge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Les tris possibles pour un User */
export enum UsersOrderBy {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
}

/** Recherches possibles pour les Users */
export interface UsersSearch {
  /** Search by email */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Search by name */
  name?: InputMaybe<Scalars['String']['input']>;
}

/** Le AccountContact, un lien entre Customer, Account, Contact, et User */
export interface VTigerAccountContact {
  /** The contact of that account-contact */
  contact: VTigerContact;
  contactNo: Scalars['NonEmptyString']['output'];
  customer: Customer;
  firstName?: Maybe<Scalars['NonEmptyString']['output']>;
  lastName?: Maybe<Scalars['NonEmptyString']['output']>;
  roles: AuthRole[];
  user?: Maybe<User>;
}

/** Sort AccountContacts */
export enum VTigerAccountContactOrderBy {
  AccountNoAsc = 'ACCOUNT_NO_ASC',
  AccountNoDesc = 'ACCOUNT_NO_DESC',
  ContactNoAsc = 'CONTACT_NO_ASC',
  ContactNoDesc = 'CONTACT_NO_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirstnameAsc = 'FIRSTNAME_ASC',
  FirstnameDesc = 'FIRSTNAME_DESC',
  LastnameAsc = 'LASTNAME_ASC',
  LastnameDesc = 'LASTNAME_DESC',
}

/** VTiger Contact */
export interface VTigerContact {
  categorieFonction?: Maybe<Scalars['NonEmptyString']['output']>;
  contactNo: Scalars['NonEmptyString']['output'];
  doNotCall?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['NonEmptyString']['output']>;
  emailOptOut?: Maybe<Scalars['Boolean']['output']>;
  fax?: Maybe<Scalars['NonEmptyString']['output']>;
  firstName?: Maybe<Scalars['NonEmptyString']['output']>;
  fonction?: Maybe<Scalars['NonEmptyString']['output']>;
  id: Scalars['PositiveInt']['output'];
  lastName?: Maybe<Scalars['NonEmptyString']['output']>;
  mobile?: Maybe<Scalars['NonEmptyString']['output']>;
  otherphone?: Maybe<Scalars['NonEmptyString']['output']>;
  phone?: Maybe<Scalars['NonEmptyString']['output']>;
  roles: AuthRole[];
  salutationType?: Maybe<Scalars['NonEmptyString']['output']>;
  title?: Maybe<Scalars['NonEmptyString']['output']>;
  /** The user linked to this contact */
  user?: Maybe<User>;
}

/** Devis VTiger */
export type VTigerQuote = Node & {
  adjustment?: Maybe<Scalars['Int']['output']>;
  carrier?: Maybe<Scalars['String']['output']>;
  contactid?: Maybe<Scalars['Int']['output']>;
  conversionRate: Scalars['Int']['output'];
  currencyId: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Scalars['Int']['output']>;
  discountPercent?: Maybe<Scalars['Int']['output']>;
  id: Scalars['PositiveInt']['output'];
  inventorymanager?: Maybe<Scalars['Int']['output']>;
  quoteNo?: Maybe<Scalars['String']['output']>;
  quotestage?: Maybe<Scalars['String']['output']>;
  sHAmount?: Maybe<Scalars['Int']['output']>;
  shipping?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Scalars['Int']['output']>;
  taxtype?: Maybe<Scalars['String']['output']>;
  termsConditions?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  validtill?: Maybe<Scalars['String']['output']>;
};

/** Devis VTiger */
export interface VTigerQuoteValidtillArgs {
  format?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
}

/** A `VTigerQuote` edge in the connection. */
export type VTigerQuoteEdge = Edge & {
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `VTigerQuote` at the end of the edge. */
  node: VTigerQuote;
};

/** A connection to a list of `VTigerQuote` values. */
export type VTigerQuotesConnection = Connection & {
  /** A list of edges which contains the `VTigerQuote` and cursor to aid in pagination. */
  edges: VTigerQuoteEdge[];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VTigerQuote` you could get from the connection. */
  totalCount: Scalars['NonNegativeInt']['output'];
};

/** Les tris possible pour un VTigerQuote */
export enum VTigerQuotesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  QuoteNoAsc = 'QUOTE_NO_ASC',
  QuoteNoDesc = 'QUOTE_NO_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  ValidtillAsc = 'VALIDTILL_ASC',
  ValidtillDesc = 'VALIDTILL_DESC',
}

/** VTigerContact Input */
export interface VtigerContactInput {
  email?: InputMaybe<Scalars['NonEmptyString']['input']>;
  firstname?: InputMaybe<Scalars['NonEmptyString']['input']>;
  lastname: Scalars['NonEmptyString']['input'];
  mobile?: InputMaybe<Scalars['NonEmptyString']['input']>;
  phone?: InputMaybe<Scalars['NonEmptyString']['input']>;
  roles: AuthRole[];
  salutationType?: InputMaybe<Scalars['NonEmptyString']['input']>;
  title?: InputMaybe<Scalars['NonEmptyString']['input']>;
}

export type CustomerUserEditCreateVtigerContactMutationVariables = Exact<{
  input: CreateVtigerContactInput;
}>;

export interface CustomerUserEditCreateVtigerContactMutation {
  createVtigerContact: { clientMutationId?: string | null };
}

export type CustomerUserEditUpdateVtigerContactMutationVariables = Exact<{
  input: UpdateVtigerContactInput;
}>;

export interface CustomerUserEditUpdateVtigerContactMutation {
  updateVtigerContact: { clientMutationId?: string | null };
}

export type CustomerAccountContactsListQueryVariables = Exact<Record<string, never>>;

export interface CustomerAccountContactsListQuery {
  customer?: {
    accountContacts: Array<{
      contact: {
        contactNo: string;
        email?: string | null;
        emailOptOut?: boolean | null;
        firstName?: string | null;
        lastName?: string | null;
        mobile?: string | null;
        otherphone?: string | null;
        phone?: string | null;
        roles: AuthRole[];
        user?: { id: string } | null;
      };
    }>;
  } | null;
}

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;

export interface UpdatePasswordMutation {
  updatePassword: { clientMutationId?: string | null };
}

export type AuthRegistrationGenerateMutationVariables = Exact<{
  input: AuthRegistrationGenerateInput;
}>;

export interface AuthRegistrationGenerateMutation {
  authRegistrationGenerate: { options: object };
}

export type AuthRegistrationVerifyMutationVariables = Exact<{
  input: AuthRegistrationVerifyInput;
}>;

export interface AuthRegistrationVerifyMutation {
  authRegistrationVerify: { ok: boolean };
}

export type PasswordCheckMobileSendCodeMutationVariables = Exact<{
  input: SendCodeInput;
}>;

export interface PasswordCheckMobileSendCodeMutation {
  sendCode: { clientMutationId?: string | null };
}

export type PasswordCreateMutationVariables = Exact<{
  input: CreatePasswordInput;
}>;

export interface PasswordCreateMutation {
  createPassword: { clientMutationId?: string | null };
}

export type RechercheCustomerQuoteQueryVariables = Exact<{
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  searchTs?: InputMaybe<Scalars['String']['input']>;
}>;

export interface RechercheCustomerQuoteQuery {
  quotes: {
    edges: Array<{
      cursor: string;
      node: {
        id: number;
        quoteNo?: string | null;
        quotestage?: string | null;
        subject?: string | null;
        total?: number | null;
        validtill?: string | null;
      };
    }>;
  };
}

export type RechercheCustomerServiceQueryVariables = Exact<{
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  searchTs?: InputMaybe<Scalars['String']['input']>;
}>;

export interface RechercheCustomerServiceQuery {
  services: {
    edges: Array<{
      node: {
        id: string;
        name: string;
        periodicityTitle?: string | null;
        statusTitle: string;
        product: { name: string; allCategories: Array<{ title: string }> };
        saleOrder?: { number: string } | null;
      };
    }>;
  };
}

export type RechercheCustomerTicketQueryVariables = Exact<{
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  search?: InputMaybe<TicketsSearch>;
  searchTs?: InputMaybe<Scalars['String']['input']>;
}>;

export interface RechercheCustomerTicketQuery {
  tickets: {
    edges: Array<{
      node: {
        changed: string;
        created: string;
        customerId?: string | null;
        customerUserId?: string | null;
        id: number;
        ticketNumber: string;
        title: string;
        changedSort: string;
        createdShort: string;
        createdSort: string;
        customerUser?: { email: string; name: string } | null;
      };
    }>;
  };
}

export type UpdateVtigerContactRolesMutationVariables = Exact<{
  input: UpdateVtigerContactRolesInput;
}>;

export interface UpdateVtigerContactRolesMutation {
  updateVtigerContactRoles: { clientMutationId?: string | null };
}

export type CustomerQuotesListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<VTigerQuotesOrderBy[] | VTigerQuotesOrderBy>;
}>;

export interface CustomerQuotesListQuery {
  quotes: {
    edges: Array<{
      cursor: string;
      node: {
        id: number;
        quoteNo?: string | null;
        quotestage?: string | null;
        subject?: string | null;
        total?: number | null;
        validtill?: string | null;
      };
    }>;
    pageInfo: {
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
}

export type CustomerInvoicesListQueryVariables = Exact<Record<string, never>>;

export interface CustomerInvoicesListQuery {
  invoices: Array<{
    date: string;
    dueDate: string;
    id: string;
    number: string;
    total: string;
    totalAmount: string;
    totalVat: string;
    date_sort: string;
    dueDate_sort: string;
  }>;
}

export type CustomerCustomerAccountGetInvoiceMutationVariables = Exact<{
  input: GetInvoiceInput;
}>;

export interface CustomerCustomerAccountGetInvoiceMutation {
  getInvoice: { content: string };
}

export type CustomerListeInterventionsListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first: Scalars['PositiveInt']['input'];
  orderBy?: InputMaybe<EvenementInterventionsOrderBy[] | EvenementInterventionsOrderBy>;
}>;

export interface CustomerListeInterventionsListQuery {
  evenementInterventions: {
    edges: Array<{
      node: {
        action: string;
        astreinte: boolean;
        debut: string;
        description: string;
        duree: Duration<true>;
        fin?: string | null;
        id: string;
        debut_date: string;
        debut_heure: string;
        debut_sort: string;
        fin_copy?: string | null;
        fin_date?: string | null;
        fin_heure?: string | null;
        devices: Array<{ display: string; id: number }>;
        services: Array<{ id: string; service: string }>;
        tickets: Array<{ id: number; ticketNumber: string; title: string }>;
        vms: Array<{ deleted: boolean; id: string; name: string }>;
      };
    }>;
    pageInfo: {
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
}

export type AuthAuthenticationGenerateMutationVariables = Exact<{
  input: AuthAuthenticationGenerateInput;
}>;

export interface AuthAuthenticationGenerateMutation {
  authAuthenticationGenerate: { options: object };
}

export type PasswordHasPhoneQueryVariables = Exact<{
  token: Scalars['NonEmptyString']['input'];
}>;

export interface PasswordHasPhoneQuery {
  hasMobile: string[];
}

export type CustomerServicesListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<IcepepperServicesOrderBy[] | IcepepperServicesOrderBy>;
}>;

export interface CustomerServicesListQuery {
  services: {
    totalCount: number;
    edges: Array<{
      node: {
        dateEnd?: string | null;
        dateStart: string;
        id: string;
        name: string;
        periodicityTitle?: string | null;
        reference?: string | null;
        statusTitle: string;
        product: { id: string; name: string; allCategories: Array<{ id: string; title: string }> };
        saleOrder?: { id: string; number: string } | null;
      };
    }>;
    pageInfo: {
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
}

export type CustomerTicketsListQueryVariables = Exact<{
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['PositiveInt']['input']>;
  orderBy?: InputMaybe<OtrsTicketsOrderBy[] | OtrsTicketsOrderBy>;
}>;

export interface CustomerTicketsListQuery {
  tickets: {
    totalCount: number;
    edges: Array<{
      node: {
        changed: string;
        created: string;
        customerUserId?: string | null;
        id: number;
        state: string;
        ticketNumber: string;
        title: string;
        type: string;
        changedSort: string;
        createdShort: string;
        customerUser?: { email: string; name: string } | null;
      };
    }>;
    pageInfo: {
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
    };
  };
}

export type AuthMutationVariables = Exact<{
  input: AuthInput;
}>;

export interface AuthMutation {
  auth: { token: string };
}

export type LogoutMutationVariables = Exact<Record<string, never>>;

export interface LogoutMutation {
  logout: { ok: boolean };
}

export type AuthedQueryVariables = Exact<Record<string, never>>;

export interface AuthedQuery {
  authed: boolean;
}

export type MessageSubscriptionVariables = Exact<{
  type: Array<Scalars['MessagePayloadType']['input']> | Scalars['MessagePayloadType']['input'];
}>;

export interface MessageSubscription {
  getMessage: { payload: PublicPayload };
}

export type PasswordResetMutationVariables = Exact<{
  input: RecoverPasswordInput;
}>;

export interface PasswordResetMutation {
  recoverPassword: { clientMutationId?: string | null };
}

export type RecoverSendMutationVariables = Exact<{
  input: RecoverSendInput;
}>;

export interface RecoverSendMutation {
  recoverSend: { clientMutationId?: string | null };
}

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export interface SendMessageMutation {
  sendMessage: { clientMutationId?: string | null };
}

export const CustomerUserEditCreateVtigerContactDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomerUserEditCreateVtigerContact' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateVtigerContactInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createVtigerContact' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerUserEditCreateVtigerContactMutation,
  CustomerUserEditCreateVtigerContactMutationVariables
>;
export const CustomerUserEditUpdateVtigerContactDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomerUserEditUpdateVtigerContact' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateVtigerContactInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVtigerContact' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerUserEditUpdateVtigerContactMutation,
  CustomerUserEditUpdateVtigerContactMutationVariables
>;
export const CustomerAccountContactsListDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerAccountContactsList' },

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customer' },

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountContacts' },

                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: { kind: 'EnumValue', value: 'LASTNAME_ASC' },
                    },
                  ],

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'contactNo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'emailOptOut' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mobile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'otherphone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'user' },

                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerAccountContactsListQuery, CustomerAccountContactsListQueryVariables>;
export const UpdatePasswordDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePassword' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePasswordInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updatePassword' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const AuthRegistrationGenerateDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthRegistrationGenerate' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthRegistrationGenerateInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authRegistrationGenerate' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'options' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthRegistrationGenerateMutation, AuthRegistrationGenerateMutationVariables>;
export const AuthRegistrationVerifyDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthRegistrationVerify' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthRegistrationVerifyInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authRegistrationVerify' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ok' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthRegistrationVerifyMutation, AuthRegistrationVerifyMutationVariables>;
export const PasswordCheckMobileSendCodeDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PasswordCheckMobileSendCode' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendCodeInput' } } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendCode' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordCheckMobileSendCodeMutation, PasswordCheckMobileSendCodeMutationVariables>;
export const PasswordCreateDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PasswordCreate' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePasswordInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPassword' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordCreateMutation, PasswordCreateMutationVariables>;
export const RechercheCustomerQuoteDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RechercheCustomerQuote' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PositiveInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTs' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotes' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'VALIDTILL_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTs' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quoteNo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quotestage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'validtill' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RechercheCustomerQuoteQuery, RechercheCustomerQuoteQueryVariables>;
export const RechercheCustomerServiceDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RechercheCustomerService' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PositiveInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTs' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'services' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'DATE_END_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTs' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'periodicityTitle' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allCategories' },

                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'title' } }],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'saleOrder' },

                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'number' } }],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusTitle' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RechercheCustomerServiceQuery, RechercheCustomerServiceQueryVariables>;
export const RechercheCustomerTicketDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RechercheCustomerTicket' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PositiveInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'TicketsSearch' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'searchTs' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tickets' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'CHANGED_DESC' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'searchTs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'searchTs' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'changed' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'changedSort' },
                              name: { kind: 'Name', value: 'changed' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'X', block: false },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'createdShort' },
                              name: { kind: 'Name', value: 'created' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'DD', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'createdSort' },
                              name: { kind: 'Name', value: 'created' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'X', block: false },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerUser' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'customerUserId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ticketNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RechercheCustomerTicketQuery, RechercheCustomerTicketQueryVariables>;
export const UpdateVtigerContactRolesDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateVtigerContactRoles' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateVtigerContactRolesInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateVtigerContactRoles' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateVtigerContactRolesMutation, UpdateVtigerContactRolesMutationVariables>;
export const CustomerQuotesListDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerQuotesList' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PositiveInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },

          type: {
            kind: 'ListType',

            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'VTigerQuotesOrderBy' } },
            },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quotes' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quoteNo' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quotestage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'subject' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'validtill' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerQuotesListQuery, CustomerQuotesListQueryVariables>;
export const CustomerInvoicesListDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerInvoicesList' },

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'invoices' },

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'date_sort' },
                  name: { kind: 'Name', value: 'date' },

                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'format' },
                      value: { kind: 'StringValue', value: 'yyyy.ooo', block: false },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'dueDate_sort' },
                  name: { kind: 'Name', value: 'dueDate' },

                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'format' },
                      value: { kind: 'StringValue', value: 'yyyy.ooo', block: false },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAmount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalVat' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerInvoicesListQuery, CustomerInvoicesListQueryVariables>;
export const CustomerCustomerAccountGetInvoiceDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CustomerCustomerAccountGetInvoice' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'GetInvoiceInput' } } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getInvoice' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'content' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerCustomerAccountGetInvoiceMutation,
  CustomerCustomerAccountGetInvoiceMutationVariables
>;
export const CustomerListeInterventionsListDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerListeInterventionsList' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'PositiveInt' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },

          type: {
            kind: 'ListType',

            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'EvenementInterventionsOrderBy' } },
            },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'evenementInterventions' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'action' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'astreinte' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'debut' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'DD, T', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'debut_date' },
                              name: { kind: 'Name', value: 'debut' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'D', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'debut_heure' },
                              name: { kind: 'Name', value: 'debut' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'T', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'debut_sort' },
                              name: { kind: 'Name', value: 'debut' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'yyyy.ooo', block: false },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'devices' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'display' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'duree' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fin' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'DD, T', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'fin_copy' },
                              name: { kind: 'Name', value: 'fin' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'yyyy-MM-dd', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'fin_date' },
                              name: { kind: 'Name', value: 'fin' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'D', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'fin_heure' },
                              name: { kind: 'Name', value: 'fin' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'T', block: false },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'services' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'service' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tickets' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'ticketNumber' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'vms' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerListeInterventionsListQuery, CustomerListeInterventionsListQueryVariables>;
export const AuthAuthenticationGenerateDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AuthAuthenticationGenerate' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthAuthenticationGenerateInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authAuthenticationGenerate' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'options' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthAuthenticationGenerateMutation, AuthAuthenticationGenerateMutationVariables>;
export const PasswordHasPhoneDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PasswordHasPhone' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'NonEmptyString' } } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasMobile' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordHasPhoneQuery, PasswordHasPhoneQueryVariables>;
export const CustomerServicesListDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerServicesList' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PositiveInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },

          type: {
            kind: 'ListType',

            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'IcepepperServicesOrderBy' } },
            },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'services' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateEnd' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'DD', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateStart' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'DD', block: false },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'periodicityTitle' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'product' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allCategories' },

                                    selectionSet: {
                                      kind: 'SelectionSet',

                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'reference' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'saleOrder' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'number' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'statusTitle' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerServicesListQuery, CustomerServicesListQueryVariables>;
export const CustomerTicketsListDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerTicketsList' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PositiveInt' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },

          type: {
            kind: 'ListType',

            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'OtrsTicketsOrderBy' } },
            },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tickets' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',

              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },

                        selectionSet: {
                          kind: 'SelectionSet',

                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'changed' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'changedSort' },
                              name: { kind: 'Name', value: 'changed' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'X', block: false },
                                },
                              ],
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'createdShort' },
                              name: { kind: 'Name', value: 'created' },

                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'format' },
                                  value: { kind: 'StringValue', value: 'DD', block: false },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customerUser' },

                              selectionSet: {
                                kind: 'SelectionSet',

                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'customerUserId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'ticketNumber' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },

                  selectionSet: {
                    kind: 'SelectionSet',

                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerTicketsListQuery, CustomerTicketsListQueryVariables>;
export const AuthDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Auth' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthInput' } } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'auth' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'token' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AuthMutation, AuthMutationVariables>;
export const LogoutDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Logout' },

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logout' },

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'ok' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const AuthedDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Authed' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'authed' } }] },
    },
  ],
} as unknown as DocumentNode<AuthedQuery, AuthedQueryVariables>;
export const MessageDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'Message' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },

          type: {
            kind: 'NonNullType',

            type: {
              kind: 'ListType',

              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'MessagePayloadType' } },
              },
            },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMessage' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'type' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'payload' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MessageSubscription, MessageSubscriptionVariables>;
export const PasswordResetDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PasswordReset' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },

          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecoverPasswordInput' } },
          },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverPassword' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PasswordResetMutation, PasswordResetMutationVariables>;
export const RecoverSendDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RecoverSend' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'RecoverSendInput' } } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recoverSend' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecoverSendMutation, RecoverSendMutationVariables>;
export const SendMessageDocument = {
  kind: 'Document',

  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendMessage' },

      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendMessageInput' } } },
        },
      ],

      selectionSet: {
        kind: 'SelectionSet',

        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendMessage' },

            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],

            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'clientMutationId' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendMessageMutation, SendMessageMutationVariables>;
