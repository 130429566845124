interface Timer {
  callback(this: void, argument?: { forced: boolean; timerId: number }): void | Promise<void>;
  interval: number;
  lastExecution: number;
  forcedInterval?: number;
  forcedIntervalId?: number;
}

interface MaybeExecuteTimerCallbackArguments {
  timerId: number;
  forced?: boolean;
}

interface SetIntervalArguments {
  callback(this: void, argument?: { forced: boolean; timerId: number }): void | Promise<void>;
  interval: number;
  forcedInterval?: number;
}

const ActivityBasedTimer = () => {
  let globalTimerId = 0;
  const timers = new Map<number, Timer>();

  const maybeExecuteTimerCallback = async ({ timerId, forced = false }: MaybeExecuteTimerCallbackArguments) => {
    const timer = timers.get(timerId);

    if (timer === undefined) {
      return;
    }

    const { callback, interval, forcedInterval, forcedIntervalId, lastExecution } = timer;

    const intervalToCheckFor = forced && forcedInterval !== undefined ? forcedInterval : interval;
    const now = Date.now();

    if (now - lastExecution < intervalToCheckFor) {
      return;
    }

    const newTimer = {
      ...timer,
      lastExecution: now,
    };

    if (forcedIntervalId !== undefined) {
      globalThis.window.clearInterval(forcedIntervalId);

      newTimer.forcedIntervalId = globalThis.window.setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        maybeExecuteTimerCallback({ timerId, forced: true });
      }, forcedInterval);
    }

    timers.set(timerId, newTimer);
    await callback({ forced, timerId });
  };

  const setInterval = async ({
    callback,
    interval,
    forcedInterval,
  }: SetIntervalArguments): Promise<number | undefined> => {
    const timerId = globalTimerId;

    if (typeof callback !== 'function' || typeof interval !== 'number') {
      return undefined;
    }

    await callback({ forced: true, timerId });

    const timer: Timer = {
      callback,
      interval,
      lastExecution: Date.now(),
    };

    if (forcedInterval !== undefined) {
      timer.forcedInterval = forcedInterval;

      timer.forcedIntervalId = globalThis.window.setInterval(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        maybeExecuteTimerCallback({ timerId, forced: true });
      }, forcedInterval);
    }

    timers.set(timerId, timer);
    globalTimerId += 1;

    return timerId;
  };

  const clearInterval = (timerId: number) => {
    const timer = timers.get(timerId);

    if (timer === undefined) {
      return;
    }

    const { forcedIntervalId } = timer;

    if (forcedIntervalId !== undefined) {
      globalThis.window.clearInterval(forcedIntervalId);
    }

    timers.delete(timerId);
  };

  const runTimersCheck = async () => {
    for await (const [timerId] of timers.entries()) {
      await maybeExecuteTimerCallback({ timerId });
    }
  };

  return {
    setInterval,
    clearInterval,
    runTimersCheck,
  };
};

export default ActivityBasedTimer;
