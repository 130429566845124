<!-- eslint-disable vue/no-root-v-if -->
<template>
  <div v-if="showMe" class="navbar-item">
    <OButton variant="danger" @click.prevent="reload()">
      <OTooltip multiline position="bottom" variant="danger">
        <template #content>
          Une nouvelle version, {{ newVersion }}, de l'application est disponible, et vous avez la version
          {{ version }}.
          <br />
          Nous vous conseillons de recharger la page.
        </template>

        <template #default>Recharger</template>
      </OTooltip>
    </OButton>
  </div>
</template>

<script setup lang="ts">
import ActivityBasedTimer from '@/plugins/activity-based-timer';

import baseDebug from './debug';

defineOptions({ name: 'NavBarNewVersion' });
const props = defineProps<{ version: string }>();

const debug = baseDebug.extend('NavBarNewVersion');

debug('init: props=%o', props);

const router = useRouter();

const newVersion = toRefs(props).version;

const versioningTimer = ActivityBasedTimer();

const showMe = computed(() => props.version !== newVersion.value);

// eslint-disable-next-line @typescript-eslint/no-floating-promises
versioningTimer.setInterval({
  async callback() {
    debug('asyncCallback');

    try {
      const index = await fetch(import.meta.env.BASE_URL);

      const body = await index.text();

      const match = /"version" content="([^"]*)"/.exec(body);

      if (match?.[1] !== undefined) {
        newVersion.value = match[1];
      }
    } catch {
      // rien
    }
  },

  // Interval classique, toutes les 2 minutes
  interval: 1000 * 60 * 2,
  // Interval forcé, toutes les heures
  forcedInterval: 1000 * 60 * 60,
});

router.beforeEach(async (_to, _from, next) => {
  debug('beforeEach');
  await versioningTimer.runTimersCheck();
  next();
});

const reload = () => {
  debug('reload');
  globalThis.location.reload();
};
</script>
