<template>
  <div v-if="$auth.loggedIn" id="logged" :class="{ dev: isDevelopment }">
    <div class="blue top"></div>

    <div class="blue right"></div>

    <NavBar />

    <NavMenu />

    <div class="content">
      <RouterView />
    </div>
  </div>

  <section v-else id="not_logged" :class="{ dev: isDevelopment }">
    <RouterView />
  </section>
</template>

<script lang="ts" setup>
import NavBar from '@/components/NavBar.vue';
import NavMenu from '@/components/NavMenu.vue';

import baseDebug from './debug';

defineOptions({ name: 'App' });

baseDebug.extend('App')('init');

const isDevelopment = computed(() => import.meta.env.DEV);
</script>

<!-- On met les style dans assets/app.scss -->
