import { createRouter, createWebHistory } from 'vue-router';
import { routes } from 'vue-router/auto-routes';

import { localAuth } from '@/plugins/auth';

import baseDebug from '../debug';

const debug = baseDebug.extend('router');

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, _from, next) => {
  const titleMeta = to.meta.title;
  // const titleParameters = to.params.title;

  const titlePart = [import.meta.env.VITE_TITLE];

  if (typeof titleMeta === 'string') {
    titlePart.unshift(titleMeta);
  }

  /*
   * Commenté car un paramètre doit apparaître dans l'url, et ce n'est pas
   * toujours pratique d'avoir le titre de la page dans l'url
   * if (titleParameters !== undefined) {
   *   titlePart.unshift(...(Array.isArray(titleParameters) ? titleParameters : [titleParameters]));
   * }
   */

  document.title = titlePart.join(' - ');

  next();
});

router.beforeEach(async (to, _from, next) => {
  const publicPages = ['/login', '/password', '/password-create'];
  const authRequired = !publicPages.includes(to.path);

  /*
   * trying to access a restricted page + not logged in
   * redirect to login page
   */
  if (!authRequired) {
    debug('beforeEach: %o no auth', to.path);
    next();
  } else if (localAuth.loggedIn) {
    if (await localAuth.authed()) {
      debug('beforeEach: %o loggedIn&authed', to.path);
      next();
      return;
    } else {
      await localAuth.logout();
    }
  }

  debug('beforeEach: %o redirect ➡ login', to.path);
  next({ name: 'login' });
});

router.onError(async (error) => {
  debug('onError: %o', error);

  /*
   * On est probablement là à cause d'un nouveau déploiement, et un chargement
   * d'une route qui a échoué, et le seul moyen de continuer c'est reload.
   */
  if (error instanceof Error && error.message === 'not authenticated') {
    await localAuth.logout();
  }

  globalThis.location.reload();
});

export default router;
