<!-- eslint-disable vue/no-root-v-if -->
<template>
  <div v-if="$auth.customers.length > 1" class="navbar-item">
    <OSelect v-model="$auth.cur_customer_id" icon="address-book" @change="changeCust()">
      <option v-for="c of $auth.customers" :key="c.id" :value="c.id">
        {{ c.name }}
      </option>
    </OSelect>
  </div>
</template>

<script setup lang="ts">
import baseDebug from './debug';

defineOptions({ name: 'NavBarCustMenu' });

const debug = baseDebug.extend('NavBarCustMenu');
debug('init');

const router = useRouter();

const changeCust = () => {
  debug('changeCust');
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  router.push({ name: 'home' });
};
</script>
