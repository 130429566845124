import '@/plugins/font-awesome';
import '@/assets/bulma.scss';
import '@/assets/extra.scss';
import '@/assets/app.scss';

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import Oruga from '@oruga-ui/oruga-next';
import { bulmaConfig } from '@ovea-dev/common-bulma-ovea';
import * as Sentry from '@sentry/vue';
import { merge } from 'lodash';
import { createApp } from 'vue';
import ShortKey from 'vue-three-shortkey';

import App from '@/App.vue';
import apolloPlugin from '@/plugins/apollo';
import authPlugin from '@/plugins/auth';
import clientCanPlugin from '@/plugins/client-can';
import router from '@/router';

const app = createApp(App);

Sentry.init({
  dsn: 'https://68430dd6ba8a94bc9ccb642fb77d9b3e@sentry.ovea.com/17',
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  tunnel: `${import.meta.env.VITE_API_BACKEND}sentryTunnel`,

  integrations: [
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true,
        hooks: ['activate', 'create', 'unmount', 'mount', 'update'],
      },
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [globalThis.location.origin],
      networkCaptureBodies: true,
      networkResponseHeaders: ['x-api-version'],
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      mask: ['input[type=password]'],
    }),
    Sentry.graphqlClientIntegration({
      endpoints: ['/graphql'],
    }),
  ],

  tracePropagationTargets: [import.meta.env.VITE_API_BACKEND],

  enabled: import.meta.env.PROD && import.meta.env.MODE !== 'e2e',
  environment: import.meta.env.MODE ?? 'dev',

  release:
    import.meta.env.VITE_VERSION === '' ? 'dev' : import.meta.env.VITE_VERSION + (import.meta.env.PROD ? '' : '-dev'),

  // Performance Monitoring
  tracesSampleRate: 1,
  // Session Replay
  replaysSessionSampleRate: 1, // TODO: descendre ça à genre 10% dans quelques mois.
  replaysOnErrorSampleRate: 1,
  /*
   * Set profilesSampleRate to 1.0 to profile every transaction.  Since
   * profilesSampleRate is relative to tracesSampleRate, the final profiling
   * rate can be computed as tracesSampleRate * profilesSampleRate For example,
   * a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would results in
   * 25% of transactions being profiled (0.5*0.5=0.25)
   */
  profilesSampleRate: 1,
});

app
  .use(router)
  .use(ShortKey)
  .use(
    Oruga,
    merge(
      {
        iconPack: 'fa',
        iconComponent: 'FontAwesomeIcon',

        field: {
          fieldInputWrapperClass: 'field-body',
        },
      },
      bulmaConfig
    )
  )
  .use(clientCanPlugin)
  .use(authPlugin)
  .use(apolloPlugin)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .component('FontAwesomeLayers', FontAwesomeLayers)
  .mount('#app');
