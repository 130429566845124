<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <OTooltip :label="`v${version}`" position="bottom">
        <span class="navbar-item">
          <RouterLink :to="{ name: 'home' }">
            <img src="@/assets/OVEA.svg" class="logo" />
          </RouterLink>

          <h5 class="no-caret">Espace Client</h5>
        </span>
      </OTooltip>

      <a
        role="button"
        :class="burgerClass"
        aria-label="menu"
        aria-expanded="false"
        data-target="navBarMenu"
        @click="burger"
      >
        <span aria-hidden="true"></span>

        <span aria-hidden="true"></span>

        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navBarMenu" :class="menuClass">
      <div class="navbar-start"></div>

      <NavBarSearchBox />

      <div class="navbar-end">
        <div class="navbar-item">
          <OButton variant="primary" class="mr-5" tag="a" href="mailto:support@ovea.com">
            <OTooltip label="Envoyez un petit mail..." position="bottom">
              <FontAwesomeIcon icon="envelope" class="mr-1" />

              <span class="long_label">Suggestions, besoin d’aide ?</span>

              <span class="short_label">besoin d'aide ?</span>
            </OTooltip>
          </OButton>
        </div>

        <NavBarCustMenu />

        <NavBarTimeout />

        <NavBarNewVersion :version="version" />

        <div v-if="$auth.beingImpersonated" class="navbar-item">
          <OButton  class="has-text-warning-dark" @click.prevent="repersonate()">
            Revenir à l'admin
          </OButton>
        </div>

        <div v-else class="navbar-item">
          <RouterLink v-slot="{ navigate }" :to="{ name: 'logout' }" custom>
            <OButton  class="navbar-item" @click.prevent="navigate()">Déconnexion</OButton>
          </RouterLink>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import NavBarCustMenu from '@/components/NavBarCustMenu.vue';
import NavBarNewVersion from '@/components/NavBarNewVersion.vue';
import NavBarSearchBox from '@/components/NavBarSearchBox.vue';
import NavBarTimeout from '@/components/NavBarTimeout.vue';
import { localAuth } from '@/plugins/auth';

import baseDebug from './debug';

defineOptions({ name: 'NavBar' });

const debug = baseDebug.extend('NavBar');
debug('init');

const router = useRouter();

const version = ref(import.meta.env.VITE_VERSION || 'dev');

const repersonate = async () => {
  debug('repersonate');
  await localAuth.logout();
  await router.push({ name: 'home' });
};

const burgerClass = reactive({
  'navbar-burger': true,
  'is-active': false,
});

const menuClass = reactive({
  'navbar-menu': true,
  'is-active': false,
});

const burger = () => {
  debug('burger: burger=%o, menu=%o', burgerClass['is-active'], menuClass['is-active']);
  burgerClass['is-active'] = !burgerClass['is-active'];
  menuClass['is-active'] = !menuClass['is-active'];
};
</script>

<style lang="scss" scoped>
@import 'bulma/sass/utilities/mixins.sass';

// La navbar a un fond blanc par défaut
.navbar {
  background-color: inherit !important;
}

h5 {
  font-size: 1.125em;
}

.navbar-brand {
  align-self: center;
  align-items: center;
}

.navbar-item {
  .logo {
    margin-right: 0.6rem;
    filter: invert(100%);
  }

  h5 {
    padding-top: 0.2rem;
  }
}

.short_label {
  display: none;

  @include touch {
    display: inline;
  }
}

.long_label {
  @include touch {
    display: none;
  }
}
</style>
