import { provideApolloClient } from '@vue/apollo-composable';

import { graphql } from '@/gql';
import type { AuthInput } from '@/gql/graphql';
import { apolloClient } from '@/plugins/apollo';

import baseDebug from './debug';

const debug = baseDebug.extend('auth.service');

provideApolloClient(apolloClient);

export const tokenName = 'client_token';

const { mutate: mutateLogin } = useMutation(
  graphql(/* GraphQL */ `
    mutation Auth($input: AuthInput!) {
      auth(input: $input) {
        token
      }
    }
  `)
);

const { mutate: mutateLogout } = useMutation(
  graphql(/* GraphQL */ `
    mutation Logout {
      logout {
        ok
      }
    }
  `)
);

const { refetch: refetchAuthed } = useQuery(
  graphql(/* GraphQL */ `
    query Authed {
      authed
    }
  `)
);

export class AuthService {
  // eslint-disable-next-line class-methods-use-this
  #getFromLocalStorage(name: string): string | undefined {
    const data = localStorage.getItem(name);

    debug('getFromLocalStorage: %s=%o', name, data);

    try {
      return data === undefined || data === null ? undefined : (JSON.parse(data) as string);
    } catch {
      return undefined;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  #setFromLocalStorage(name: string, token: string | undefined) {
    debug('setFromLocalStorage: %s=%o', name, token);

    if (token === undefined) {
      localStorage.removeItem(name);
    } else {
      localStorage.setItem(name, JSON.stringify(token));
    }
  }

  public set token(token: string | undefined) {
    this.#setFromLocalStorage(tokenName, token);
  }

  public get token(): string | undefined {
    return this.#getFromLocalStorage(tokenName);
  }

  public async login(user: AuthInput): Promise<string | undefined> {
    debug('login: %o', user);

    const resp = await mutateLogin({
      input: user,
    });

    if (resp?.data) {
      debug('login: logged in');
      this.token = resp.data.auth.token;
      return resp.data.auth.token;
    }

    debug('login: not auth');

    return undefined;
  }

  public impersonate(token: string): string {
    debug('impersonate: trying');

    debug('impersonate: ok');
    this.token = token;

    return token;
  }

  // eslint-disable-next-line class-methods-use-this
  public async isAuthed(): Promise<boolean> {
    const authed = await refetchAuthed();

    return authed?.data.authed === true;
  }

  public async logout(): Promise<void> {
    debug('logout');

    if (this.token !== undefined) {
      try {
        await mutateLogout();
      } catch (error) {
        debug('logout error: %o', error);
      }

      this.token = undefined;
    }
  }
}

export default new AuthService();
