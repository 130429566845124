import type { AuthRole } from '@ovea-dev/si-client-common-roles';
import type { PayloadUser } from '@ovea-dev/si-client-common-types';
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';

import type { AuthInput } from '@/gql/graphql';
import AuthService from '@/plugins/auth.service';
import { AuthKey } from '@/plugins/keys';
import type { OurPayload } from '@/plugins/payload';
import { decodeJwt } from '@/plugins/payload';

import baseDebug from './debug';

const debug = baseDebug.extend('auth');

interface InternalData {
  data: OurPayload | Record<string, never>;
}

class LocalAuth {
  #service: typeof AuthService;

  /*
   * Le vrai stockage de données est encapsulé dans un object réactif, sinon les
   * modifications dans login et/ou logout ne se répercutent pas dans les
   * composants utilisant $auth qui sont toujours chargés, comme App.vue ou
   * NavBar.vue.
   */
  #data = reactive<InternalData>({
    data: {},
  });

  // eslint-disable-next-line unicorn/no-useless-undefined
  #cur_customer_id = useLocalStorage<string | undefined>('cur_customer_id', undefined);

  constructor(authService: typeof AuthService) {
    this.#service = authService;

    const data = this.#service.token;

    if (data !== undefined) {
      this.data = data;
    }
  }

  private set data(value: OurPayload | Record<string, never> | string) {
    let newPayload: OurPayload | Record<string, never> = {};

    debug('set data: value=%o', value);

    if (typeof value === 'string') {
      try {
        newPayload = decodeJwt(value);
      } catch {
        newPayload = {};
      }
    } else {
      newPayload = value;
    }

    if ('user' in newPayload) {
      Sentry.addBreadcrumb({
        category: 'auth',
        message: `Authenticated user ${newPayload.user.email}`,
        level: 'info',
        data: newPayload.user,
      });

      Sentry.setUser({
        id: newPayload.user.id,
        email: newPayload.user.email,
      });
    } else {
      // eslint-disable-next-line unicorn/no-null
      Sentry.setUser(null);
    }

    debug('set data: newPayload=%o', newPayload);

    this.#data.data = newPayload;

    // eslint-disable-next-line no-self-assign
    this.cur_customer_id = this.cur_customer_id;

    if (this.cur_customer_id === undefined) {
      this.cur_customer_id = newPayload?.user?.customers[0]?.id;
    }
  }

  public get data(): OurPayload | Record<string, never> {
    return this.#data.data;
  }

  public set cur_customer_id(value: string | undefined) {
    this.#cur_customer_id.value = this.customers.some((c) => c.id === value) ? value : undefined;
    debug('set cur_customer_id: %o', this.#cur_customer_id.value);
  }

  public get cur_customer_id() {
    return this.#cur_customer_id.value;
  }

  public get user(): PayloadUser | undefined {
    if ('user' in this.data) {
      return this.data.user;
    }

    return undefined;
  }

  public get customers() {
    if (this.user) {
      return this.user.customers;
    }

    return [];
  }

  public get cur_customer() {
    return this.user?.customers.find((c) => c.id === this.cur_customer_id);
  }

  public get roles() {
    if (this.cur_customer) {
      return this.cur_customer.roles;
    }

    return [];
  }

  public is(role: AuthRole) {
    debug('is(%o) ➡ %o', role, this.roles.includes(role));
    return this.roles.includes(role);
  }

  public get exp() {
    if ('exp' in this.data && this.data.exp !== undefined) {
      return this.data.exp;
    }

    return 0;
  }

  public get loggedIn() {
    const now = Date.now() / 1000; // Date.now -> milliseconds.

    return this.exp > now;
  }

  public async authed() {
    return this.#service.isAuthed();
  }

  public get beingImpersonated() {
    return this.data.impersonator?.id !== undefined;
  }

  public async login(user: AuthInput) {
    debug('login: user=%o', user);

    const data = await this.#service.login(user);

    debug('login: data=%o', data);

    if (data !== undefined) {
      this.data = data;
    }
  }

  public impersonate(token: string) {
    debug('impersonate: token=%o', token);

    this.data = this.#service.impersonate(token);
  }

  public async logout() {
    debug('logout');
    await this.#service.logout();
    this.data = {};
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $auth: LocalAuth;
  }
}

export const localAuth = new LocalAuth(AuthService);

export type LocalAuthClass = LocalAuth;

export default {
  install(app: App) {
    debug('install');
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$auth = localAuth;
    app.provide(AuthKey, localAuth);
  },
};
