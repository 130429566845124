<template>
  <div class="navbar-item">
    <form>
      <input
        v-model="searchString"
        v-shortkey.focus="['ctrl', 'k']"
        placeholder="Recherche..."
        type="search"
        required
      />

      <button type="submit"><FontAwesomeIcon icon="magnifying-glass" /></button>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router';

import baseDebug from './debug';

defineOptions({ name: 'NavBarSearchBox' });

const debug = baseDebug.extend('NavBarSearchBox');
debug('init');

const searchString = useRouteQuery('q', '', { mode: 'push' });

const router = useRouter();

watchEffect(() => {
  if (router.currentRoute.value.name !== 'Recherche' && searchString.value !== '') {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({ name: 'Recherche', query: { q: searchString.value } });
  }
});
</script>

<style lang="scss" scoped>
$default-color: var(--gris-6);
$focused-color: var(--azur);
$invalid-color: var(--corail-110);
$valid-color: var(--canari);

form {
  display: inline-flex;
  padding: 4px;
  border: 2px solid $default-color;
  outline: none;
  border-radius: 30px;

  &:focus-within {
    border-color: $focused-color;
  }

  input {
    outline: none;
    border: none;
    width: 0;
    background-color: transparent;
    color: black;
    font-size: 16px;
    padding: 0;
    transition: width 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.33);

    &::placeholder {
      color: lightgray;
    }
  }

  &:hover input,
  input:focus,
  input:valid {
    margin-left: 1rem;
    width: 20rem;
  }

  button {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: $default-color;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-appearance: none;
  }

  &:focus-within button,
  input:focus + button {
    background-color: $invalid-color;
    > * {
      color: white;
    }
  }

  input:valid + button {
    background-color: $valid-color;
    > * {
      color: black;
    }
  }
}
</style>
