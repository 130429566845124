import type { Payload } from '@ovea-dev/si-client-common-types';
import { fromByteArray, toByteArray } from 'base64-js';
import utf8 from 'utf8';

import baseDebug from './debug';

const debug = baseDebug.extend('payload');

// Pris de jose
export type OurPayload = Payload & {
  /**
   * JWT Issuer
   *
   * @see [RFC7519#section-4.1.1](https://www.rfc-editor.org/rfc/rfc7519#section-4.1.1)
   */
  iss?: string;

  /**
   * JWT Subject
   *
   * @see [RFC7519#section-4.1.2](https://www.rfc-editor.org/rfc/rfc7519#section-4.1.2)
   */
  sub?: string;

  /**
   * JWT Audience
   *
   * @see [RFC7519#section-4.1.3](https://www.rfc-editor.org/rfc/rfc7519#section-4.1.3)
   */
  aud?: string | string[];

  /**
   * JWT ID
   *
   * @see [RFC7519#section-4.1.7](https://www.rfc-editor.org/rfc/rfc7519#section-4.1.7)
   */
  jti?: string;

  /**
   * JWT Not Before
   *
   * @see [RFC7519#section-4.1.5](https://www.rfc-editor.org/rfc/rfc7519#section-4.1.5)
   */
  nbf?: number;

  /**
   * JWT Expiration Time
   *
   * @see [RFC7519#section-4.1.4](https://www.rfc-editor.org/rfc/rfc7519#section-4.1.4)
   */
  exp?: number;

  /**
   * JWT Issued At
   *
   * @see [RFC7519#section-4.1.6](https://www.rfc-editor.org/rfc/rfc7519#section-4.1.6)
   */
  iat?: number;
};

/*
 * Le base64 contient de l'utf-8, mais le décodeur n'a aucun moyen de le
 * savoir, du coup, comme moi je sais que c'en est vraiment, on peut le
 * forcer.
 */
export const decodeJwt = <T>(value: string) => {
  debug('decodeJwt: value=%o', value);

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  let returnValue = {} as T;

  try {
    let base64 = value.split('.')[1];

    if (base64.length % 4 > 0) {
      base64 += '='.repeat(4 - (base64.length % 4));
    }

    const s = String.fromCodePoint(...toByteArray(base64));

    debug('s: %o', s);

    returnValue = JSON.parse(utf8.decode(s)) as T;
  } catch (error) {
    debug('decodeJwt: error=%o', error);
  }

  debug('decodeJwt: return=%o', returnValue);
  return returnValue;
};

export const secretToken = 'SuperSecretToken';

// On a pas besoin d'une signature valide, donc
export const makeJwt = (payload: OurPayload) => {
  debug('makeJwt: payload=%o', payload);

  const returnValue = [
    {
      alg: 'HS256',
      typ: 'JWT',
    },
    payload,
    secretToken,
  ]
    .map((s) => JSON.stringify(s))
    .map((s) => utf8.encode(s))
    .map((o) => [...o].map((s) => s.codePointAt(0)))
    .map((o) => fromByteArray(o as unknown as Uint8Array).replace(/=+$/, ''))
    .join('.');

  debug('makeJwt: return=%o', returnValue);

  return returnValue;
};
