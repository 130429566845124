import type { AuthRole } from '@ovea-dev/si-client-common-roles';
import type { App } from 'vue';

import { localAuth } from '@/plugins/auth';
import { CanKey } from '@/plugins/keys';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $can(role: AuthRole): boolean;
  }
}

export default {
  async install(app: App): Promise<void> {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$can = (role: AuthRole) => localAuth.roles.includes(role);

    app.provide(CanKey, (role: AuthRole) => localAuth.roles.includes(role));
  },
};
