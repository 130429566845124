<!-- eslint-disable vue/no-root-v-if -->
<template>
  <div v-if="showMe" class="navbar-item" >
    <OButton :variant="variant">
      <OTooltip label="Votre session va expirer dans..." position="bottom" :variant="variant">
        {{ prettyTime }}
      </OTooltip>
    </OButton>
  </div>
</template>

<script lang="ts" setup>
import { localAuth } from '@/plugins/auth';

import baseDebug from './debug';

defineOptions({ name: 'NavBarTimeout' });

const debug = baseDebug.extend('NavBarTimeout');
debug('init');

const router = useRouter();

const now = ref(Date.now() / 1000);

let remainingTimer: ReturnType<typeof setInterval>;

const remainingTime = computed(() => +(localAuth.exp - now.value));

const showMe = computed(() => remainingTime.value < 1800);

const variant = computed(() => (remainingTime.value > 300 ? 'warning' : 'danger'));

const prettyTime = computed(() => {
  if (remainingTime.value > 180) {
    return `${Math.round(remainingTime.value / 60)}mn`;
  } else if (remainingTime.value > 60) {
    return `${Math.round(remainingTime.value / 60)}mn${Math.round(remainingTime.value % 60)}s`;
  } else if (remainingTime.value > 0) {
    return `${Math.round(remainingTime.value)}s`;
  } else {
    return 'Session expirée';
  }
});

onUnmounted(() => {
  if (remainingTimer !== undefined) {
    clearInterval(remainingTimer);
  }
});

watch(
  now,
  () => {
    debug('watch:now');

    if (remainingTimer !== undefined) {
      clearInterval(remainingTimer);
    }

    // eslint-disable-next-line no-nested-ternary
    const timeout = remainingTime.value > 300 ? (import.meta.env.MODE === 'development' ? 2000 : 60_000) : 1000;

    remainingTimer = setInterval(() => {
      debug('remainingTimer %o', remainingTime.value);
      now.value = Date.now() / 1000; // Date.now -> milliseconds.

      if (remainingTime.value < 0) {
        clearInterval(remainingTimer);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        router.push({ name: 'logout' });
      }
    }, timeout);
  },
  { immediate: true }
);
</script>
