import { makeExecutableSchema } from '@graphql-tools/schema';
import type { IntrospectionQuery } from 'graphql';
import { buildClientSchema, GraphQLError, GraphQLScalarType, Kind } from 'graphql';
import { Duration } from 'luxon';

import introspection from '@/gql/graphql.schema.json';

const typeDefs = buildClientSchema(introspection as unknown as IntrospectionQuery);

const DurationScalarType = new GraphQLScalarType<Duration<true>, string>({
  name: 'Duration',

  serialize: (parsed) => {
    if (parsed instanceof Duration && parsed.isValid) {
      return parsed.toISO()!;
    }

    throw new GraphQLError(`bad duration ${String(parsed)}`, {
      extensions: { code: 'BAD_USER_INPUT', parsed },
    });
  },

  parseValue: (raw) => {
    if (typeof raw === 'string') {
      const d = Duration.fromISO(raw);

      if (d.isValid) {
        return d;
      }
    }

    throw new GraphQLError(`bad duration value ${String(raw)}`, {
      extensions: { code: 'BAD_USER_INPUT', raw },
    });
  },

  parseLiteral: (ast) => {
    if (ast.kind === Kind.STRING) {
      const d = Duration.fromISO(ast.value);

      if (d.isValid) {
        return d;
      }

      throw new GraphQLError(`bad duration value ${ast.value}`, { extensions: { ast } });
    }

    throw new GraphQLError(`bad duration type ${ast.kind}`, { extensions: { ast } });
  },
});

const resolvers = {
  Duration: DurationScalarType,
};

const schema = makeExecutableSchema({
  typeDefs,
  resolvers,
});

export default schema;
